import React from 'react';
import './Home.css';

const Home: React.FC<{ onNavItemClicked: (sectionName: string) => void }> = ({ onNavItemClicked }) => {
    const learnMoreClicked = (sectionName: string) => () => {
        const url = "https://gradassist.actualaiz.com/";
        window.open(url, '_blank', 'noopener,noreferrer');
    };
    
    return (
        <div className='home-section'>
            <video className="background-video" autoPlay loop muted playsInline>
                <source src="background-1.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className="overlay-text">
                <span className='phrase'>Actualize your Higher Education Dreams through</span>
                <span className='expert-driven-ai'>Expert-Driven AI</span>
                <br></br>
                {/* <br></br> */}
                <span className='new-product'><span className='update'>New</span><span className='colon'>:</span>Introducing Grad Assist - Your Companion in the Graduate Journey</span>
                <br></br>
                <div className='learn-more' onClick={learnMoreClicked('forumSection')}>
                    <div>Visit Grad Assist</div>
                </div>
            </div>
        </div> 
    );
}

export default Home;

