"use client";

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import './Navbar.css';

import DragHandleIcon from '@mui/icons-material/DragHandle';
import CloseIcon from '@mui/icons-material/Close';

const Navbar: React.FC<{ onNavItemClicked: (sectionName: string) => void }> = ({ onNavItemClicked }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const handleMenuClick = (e: React.MouseEvent) => {
        setIsExpanded(true);
    };

    const handleCloseClick = (e: React.MouseEvent) => {
        setIsExpanded(false);
    };

    const handlePageSwitch = () => {
        navigate("/");
        window.scrollTo(0, 0);
        setIsExpanded(false);
    }

    const handleNavItemClick = (sectionName: string) => () => {
        onNavItemClicked(sectionName);
        setIsExpanded(false);
    };

    const handleLogoClick = () => {
        navigate('/');
        window.scrollTo(0, 0);
    };

    const handleMoreAboutUsClick = () => {
        navigate('/about-us');
    window.scrollTo(0, 0);
        setIsExpanded(false);
    };

    const handlePrivacyClick = () => {
        navigate('/privacy');
    window.scrollTo(0, 0);
        setIsExpanded(false);
    };

    const handleContactClick = () => {
        navigate('/contact');
    window.scrollTo(0, 0);
        setIsExpanded(false);
    };

    return (
        <div className='navbar-section'>
            <div className='nav-icons-container'>
                <div className='menu-icon-container' onClick={handleMenuClick}>
                    <DragHandleIcon className='nav-menu-icon' style={{ fontSize: '50px' }} />
                </div>
                <div className='logo-container' onClick={handleLogoClick}>
                    <img className='logo' src='logo.svg' />
                    <div className='logo-text'>
                        <span className='logo-text-black'>Actual</span>
                        <span className='logo-text-orange'>AI</span>
                        <span className='logo-text-black'>z</span>
                    </div>
                </div>
            </div>
            <div className={`navbar-items ${isExpanded ? 'navbar-expanded' : ''}`}>
                {isExpanded && 
                    <div className='close-icon' onClick={handleCloseClick}>
                        <CloseIcon className='nav-menu-icon' style={{ fontSize: '50px' }} />
                    </div>}
                {location.pathname === "/about-us" && <> <div className="nav-item-expanded" onClick={handlePageSwitch}>Home</div>  <div className="nav-item-expanded" onClick={handleContactClick}>Contact Us</div> <div className="nav-item-expanded" onClick={handlePrivacyClick}>Privacy</div> </>}

                {location.pathname === "/privacy" && <> <div className="nav-item-expanded" onClick={handlePageSwitch}>Home</div>  <div className="nav-item-expanded" onClick={handleContactClick}>Contact Us</div> <div className="nav-item-expanded" onClick={handleMoreAboutUsClick}>About Us</div>  </>}

                {location.pathname === "/contact" && <> <div className="nav-item-expanded" onClick={handlePageSwitch}>Home</div> <div className="nav-item-expanded" onClick={handleMoreAboutUsClick}>About Us</div> <div className="nav-item-expanded" onClick={handlePrivacyClick}>Privacy</div> </>}

                {location.pathname === "/" && <> <div className="nav-item-expanded" onClick={handleNavItemClick('homeSection')}>Home</div>
                <div className="nav-item-expanded" onClick={handleNavItemClick('forumSection')}>GradEcho</div>
                <div className="nav-item-expanded" onClick={handleNavItemClick('productsSection')}>Products</div>
                <div className="nav-item-expanded" onClick={handleMoreAboutUsClick}>About Us</div> 
                <div className="nav-item-expanded" onClick={handleContactClick}>Contact Us</div>
                <div className="nav-item-expanded" onClick={handlePrivacyClick}>Privacy</div> </>}
                
            </div>
        </div>    
    );
}

export default Navbar;
