import React from 'react';
import './Products.css';

const Products: React.FC = () => {
    const productInfo = [
        { src: 'GradSelect-Banner.png', text: 'Finding the best match universities.', title: "GradSelect", link: 'https://gradassist.actualaiz.com/' },
        { src: 'SOPify-Banner.jpg', text: 'Get expert-driven AI-based feedback to improve your statement of purpose (SOP).', title: "SoPify", link: 'https://gradassist.actualaiz.com/' },
        { src: 'GradFinance-Banner.jpg', text: 'Plan and manage your finances for graduate school seamlessly.', title: "GradFinance" }
    ];

    return (
        <div className='products-section'>
            <div className='product-container'>
                {productInfo.map((product, index) => (
                    <div key={index} className='product-item'>
                        {/* Conditionally wrap image and caption with a link if 'link' exists */}
                        {product.link ? (
                            <a href={product.link} target="_blank" rel="noopener noreferrer">
                                <img src={product.src} alt={product.text} className='product-image' />
                                <div className='product-title'>{product.title}</div>
                                <div className='product-text'>{product.text}</div>
                            </a>
                        ) : (
                            <>
                                <img src={product.src} alt={product.text} className='product-image' />
                                <div className='product-title'>{product.title}</div>
                                <div className='product-text'>{product.text}</div>
                            </>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Products;
