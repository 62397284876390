import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Privacy.css';

const Privacy: React.FC = () => {
    return (
        <div className="privacy-container">
        <div className='p-con'>
    <h1 id="privacy-main-heading">Privacy Policy</h1>
    <p><strong>Last updated:</strong> <em>Tuesday, 9 July 2024</em></p>
    
    <h2 className="privacy-section-heading">Introduction</h2>
    <p>Welcome to ActualAIz ("we," "our," or "us"). We are committed to protecting your privacy and providing a safe online experience for our users. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website actualaiz.com (the "Site") and use our services.

    <br/><br/>
        
    Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the site.
    </p>
    
    <h2 className="privacy-section-heading">Information We Collect</h2>
    <p>We collect information that you provide directly to us, information we obtain automatically when you visit the Site, and information from third-party sources.</p>

    <h3 className="privacy-subsection-heading">Information You Provide Us</h3>
    <p>
        <li>Personal information (e.g., name, email address) when you register for an account</li>
        <li>Information you post on our forums</li>
        <li>Communications you send to us</li>
    </p>
    
    <h3 className="privacy-subsection-heading">Information We Collect Automatically</h3>
    <p>
        <li>Usage details (e.g., access times, pages viewed)</li>
        <li>Device information (e.g., IP address, browser type)</li>
        <li>Location information</li>
        <li>Information collected through cookies and similar technologies</li>
    </p>

    <h3 className="privacy-section-heading">How We Use Your Information</h3>
    <p>We use the information we collect to:
        <li>To provide and maintain our forum services.</li>
        <li>To personalize your experience on our website.</li>
        <li>To communicate with you, respond to your questions, and provide customer support.</li>
        <li>To improve our website and services based on user feedback.</li>
        <li>To monitor and analyze usage and trends to enhance your experience.</li>
        <li>To serve relevant advertisements through Google Ads.</li>
    </p>
    
    <h3 className="privacy-section-heading">Cookies and Tracking Technologies</h3>
    <p>We use cookies and other tracking technologies to enhance your experience on the Site. Cookies are small data files stored on your device that help us remember your preferences, authenticate your account, and analyze Site usage. You can manage cookies through your browser settings, but please note that disabling cookies may affect the functionality of the Site.</p>

    <h3 className="privacy-section-heading">Google Ads and Third-Party Advertising</h3>
    <p>
        We use Google Ads and other third-party advertising companies to serve ads when you visit our Site. These companies may use information about your visits to this and other websites to provide advertisements about goods and services of interest to you.
        <br/><br/>
        Third-party vendors, including Google, use cookies to serve ads based on a user's prior visits to our website or other websites. Google's use of advertising cookies enables it and its partners to serve ads to our users based on their visits to our sites and/or other sites on the Internet.
        <br/><br/>
        Users may opt out of personalized advertising by visiting <a href="https://www.google.com/settings/ads">Ads Settings</a>. Alternatively, users can opt out of a third-party vendor's use of cookies for personalized advertising by visiting <a href="www.aboutads.info">www.aboutads.info</a>.
    </p>

    <h3 className="privacy-section-heading">Data Sharing and Disclosure</h3>
    <p>We do not sell or rent your personal information to third parties. However, we may share your information in the following circumstances:
        <li>With service providers who assist us in operating the Site and conducting our business</li>
        <li>With academic institutions or organizations for research or collaboration purposes</li>
        <li>In response to legal requests or to protect the rights and safety of GradEcho and its users</li>
        <li>In the event of a merger, acquisition, or sale of assets, where user information may be transferred as part of the transaction</li>
    </p>

    <h3 className="privacy-section-heading">Data Security</h3>
    <p>We aim to protect your personal information through a system of organizational and technical security measures. However, please be aware that no electronic transmission or storage method is 100% secure.
    </p>

    <h3 className="privacy-section-heading">Your Rights and Choices</h3>
    <p>Depending on your location, you may have certain rights regarding your personal information, including:
        <li>Access to your personal information</li>
        <li>Correction of inaccurate information</li>
        <li>Deletion of your personal information</li>
        <li>Objection to processing</li>
        <li>Data portability</li>
    To exercise these rights, please contact us using the information provided in the "Contact Us" section.
    </p>

    <h3 className="privacy-section-heading">GDPR Compliance</h3>
    <p>For users in the European Economic Area (EEA), we comply with the EU General Data Protection Regulation (GDPR). We process personal data on the following legal bases: consent, contract performance, legitimate interests, and compliance with legal obligations.
    </p>

    <h3 className="privacy-section-heading">CCPA Compliance</h3>
    <p>For California residents, we comply with the California Consumer Privacy Act (CCPA). You have the right to know what personal information we collect, request deletion of your personal information, and opt-out of the sale of personal information (note that we do not sell personal information).
    </p>

    <h3 className="privacy-section-heading">International Data Transfers</h3>
    <p>Your information may be transferred to and processed in countries other than your own. We ensure appropriate safeguards are in place to protect your information in compliance with applicable laws.
    </p>

    <h3 className="privacy-section-heading">Children’s Policy</h3>
    <p>Our website is not intended for children under 13 years of age, and we do not knowingly collect information from children under 13. If we discover that a child under 13 has provided us with personal information, we will immediately delete this from our servers.
    </p>

    <h3 className="privacy-section-heading">Changes to This Privacy Policy
    </h3>
    <p>We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting the updated policy on the Site or by other means. Your continued use of the Site after the effective date of the updated policy constitutes your acceptance of the changes.
    </p>

    <h2 className="privacy-section-heading">Contact Us</h2>
    <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at <a href="mailto:support@actualaiz.com">support@actualaiz.com</a>.
    </p>

    <h2 className="privacy-section-heading">Legal Information</h2>
    <p>We may disclose your information to comply with legal obligations, enforce our terms of service, or protect the rights and safety of our users and the public.
    <br/><br/><br/>
    By using our Site, you consent to our privacy policy and agree to its terms.
    </p>
</div>
</div>

    );
}

export default Privacy;
