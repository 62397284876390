import React, { useState, useEffect } from 'react';
import Typist from 'react-typist';
import './Forum.css';

const Forum: React.FC = () => {
    const visitNowClicked = () => {
        const url = "https://gradecho.actualaiz.com";
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
        <div className='forum-container'>
            <div className='forum-info'>
                <span className='forum-title'>GradEcho: The Student Network</span>
                <span className='forum-des'>Connect, Collaborate and Succeed with GradEcho—the networking forum for students on path to graduate studies. Connect with peers, professors, and alumni, and transform their insights into your academic success. With GradEcho, your journey to grad school is just a conversation away.</span>
            </div>
            <div className='visit-now' onClick={visitNowClicked}>
                <div>Visit Now</div>
            </div>
            <video className="forum-video" autoPlay loop muted playsInline>
                <source src="GradEcho2.mov" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
    );
}

export default Forum;
