import React, { useState } from 'react';
import './Team.css';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import CloseIcon from '@mui/icons-material/Close';

interface TeamMember {
    id: number;
    name: string;
    img: string;
    info: string;
}

const teamMembers: TeamMember[] = [
    { id: 1, name: 'Dr Zafar Ayyub Qazi', img: 'zafarqazi.png', info: 'Dr. Zafar Ayyub Qazi is a co-founder of Actualaiz and a distinguished computer scientist. He has been recognized with multiple awards, including the Google Faculty Research Award, and was named one of the top 100 most influential computer scientists in networked systems by the AI 2000 list. He also serves as an Assistant Professor of Computer Science at Lahore University of Management Sciences (LUMS), a position he has held since 2018. Prior to LUMS, he was a postdoctoral scholar at UC Berkeley\'s EECS Department from 2016 to 2017. Dr. Qazi earned his PhD from the State University of New York at Stony Brook. He has extensive experience in designing and building large-scale distributed systems. His current research explores the applications of Large Language Models (LLMs), design of large-scale distributed systems, mobile computing, and improving Internet affordability.' },
    { id: 2, name: 'Dr Agha Ali Raza', img: 'aghaali.jpg', info: 'Dr. Agha Ali Raza, a co-founder of ActualAIz and Assistant Professor at LUMS, is a globally recognized leader in Machine Learning, Speech and Natural Language Processing, and technologies for Emerging Markets. A Fulbright scholar with a Ph.D. from Carnegie Mellon University, USA, Dr. Raza has spent over 19 years pioneering accessible and inclusive digital solutions. His innovative projects, including the viral speech-based platform Polly, the maternal health hotline Super Abbu, the IVR social network Baang, the quora-like service for low-literates Sawaal, and the voice-based crowd-work platform Karamad, have empowered over 300,000 low-literate and non-tech-savvy individuals across Pakistan, India, and West Africa. These platforms provide crucial information on health, immunization, jobs, COVID-19, Ebola, and more, truly transforming lives. Dr. Raza\'s work has attracted significant funding from prestigious organizations including UNICEF, GIZ, Facebook/Meta, the Gates Foundation, NIH, NAKFI, Google, and HEC, totaling over USD 1.6 million. His vision is to create a safe, equitable digital world where every individual has equitable opportunities to thrive.    ' },
    { id: 3, name: 'Dr Ihsan Ayyub Qazi', img: 'ihsanqazi.png', info: 'Dr. Ihsan Ayyub Qazi is a cofounder of Actualaiz. He is celebrated for his outstanding contributions to computer science and is a recipient of several prestigious awards including the Google Faculty Research Award, three Meta/Facebook Integrity Foundational Research Awards, ACM SIGCOMM CCR Best Paper Award, Google exploreCSR Award, and the Sheth International Young Alumni Achievement Award 2021 from the University of Pittsburgh, USA. He also serves as a Tenured Associate Professor of Computer Science at Lahore University of Management Sciences (LUMS), where he held the position of Department Chair from 2018 to 2021. As a Principal Investigator at the National Center in Big Data & Cloud Computing (NCBC), he embodies the spirit of innovation that is crucial for entrepreneurs. His research interests are in networked systems and their societal impacts. His current research centers on Large Language Models (LLMs), cloud/edge computing, digital development, countering misinformation and privacy-preserving machine learning. He was a visiting Research Scientist at the University of California at Berkeley in 2017 and at RWTH Aachen University, Germany in 2016, worked as a Postdoctoral Research Fellow in Australia from 2010-11, and received his Ph.D. in Computer Science from the University of Pittsburgh, USA in 2010.' },
    { id: 4, name: 'Dr Mukhtiar Ahmad', img: 'mukhtiarahmed.jpeg', info: 'Dr. Mukhtiar Ahmad holds the position of Team Lead at ActualAIz Pvt. Ltd., leveraging over 14 years of extensive industry experience in safety-critical embedded systems and systems design. He obtained his Ph.D. from LUMS in 2023, where he published his research at prestigious conferences such as ACM SIGCOMM, ACM CoNEXT, and IEEE Transactions on Networking, focusing primarily on supporting cellular networks for latency-sensitive edge applications. Dr. Mukhtiar\'s recent endeavors in exploring the applications of Large Language Models (LLMs) and RAG exemplify his steadfast commitment to innovation, positioning him at the forefront of technological advancement.' },
    { id: 5, name: 'Muhammad Tahir', img: 'tahirmunir.jpeg', info: 'Muhammad Tahir is a valuable team member of Actualize and a Senior Research Associate at Lahore University of Management Sciences (LUMS). With over six years of experience in machine learning, Muhammad specializes in Federated Learning and privacy-preserving machine learning. Muhammad\'s current research initiatives are centered on domain-specific Large Language Models (LLMs). His scholarly contributions are well-recognized, with publications presented at top-tier conferences such as ECML and The Web Conference (WWW). At Actualize, Muhammad leverages his deep expertise to innovate and train domain-specific LLMs, developing tailored and efficient machine learning solutions. His role at Actualize and LUMS demonstrates his commitment to advancing the boundaries of machine learning research and its practical applications.    ' },
    { id: 7, name: 'Mutahar Ali', img: 'mutahar.png', info: 'Mutahar Ali is a Research Associate at ActualAIz. He earned his Bachelor\'s in Computer Science from Lahore University of Management Sciences (LUMS), graduating in 2023. Mutahar has a diverse research background with experience in distributed systems, computer networks, and applied machine learning research. At ActualAIz, Mutahar works on development of scalable software for LLM-powered applications, In-Context Learning through Retrieval Augmented Generation (RAG), and software deployment.' },
    { id: 8, name: 'Samee Arif', img: 'samee.jpeg', info: 'Samee Arif is a Research Associate at Actualaiz. He completed his Bachelor of Science in Computer Science in 2023 from LUMS. He worked as a Research Assistant at the Center for Speech and Language Technologies from August 2021 to May 2023. His research focuses on Information & Communication Technologies for Development, Human-Computer Interaction, and Speech and Natural Language Processing. He is committed to leveraging these fields to create meaningful social impacts, particularly by enhancing accessibility and usability of technology. To improve inclusivity and accessibility of Urdu in natural language processing, he recently published a research paper at LREC-COLING 2024 – Joint International Conference on Computational Linguistics, Language Resources and Evaluation.' },
    { id: 9, name: 'Hamid Nawaz', img: 'hamid.png', info: 'Hamid is a Management Science (Operations Research) graduate from LUMS Class of 2023. He currently serves as the Product and Business Development Manager at Actualaiz. Hamid is a trained data scientist and analytics expert with 2 years of demonstrated experience with renowned Fin Tech startups, International Consultancie, and the Federal Ministry of Planning and Developmen. He has also served as a research associate at LUMS, with his work primarily focusing on leveraging data science and machine learning to aid development policy. At Actualaiz, Hamid utilizes his joint expertise in data based products and business understanding to optimize our business and product strategy.' },
    { id: 10, name: 'Wassay Sajjad', img: 'wassay.png', info: 'Description of the new team member.' },
    { id: 11, name: 'Hamza Khawaja', img: 'hamza_khawaja.png', info: 'Description of the new team member.' },
    { id: 12, name: 'Shazer Ali', img: 'shazer.png', info: 'Description of the new team member.' },

];

const Team: React.FC = () => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [selectedMember, setSelectedMember] = useState<TeamMember | null>(null);

    const openModal = (member: TeamMember) => {
        setSelectedMember(member);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const nextMember = () => {
        const currentIndex = teamMembers.findIndex(m => m.id === selectedMember?.id);
        const nextIndex = (currentIndex + 1) % teamMembers.length;
        setSelectedMember(teamMembers[nextIndex]);
    };

    const previousMember = () => {
        const currentIndex = teamMembers.findIndex(m => m.id === selectedMember?.id);
        const prevIndex = (currentIndex - 1 + teamMembers.length) % teamMembers.length;
        setSelectedMember(teamMembers[prevIndex]);
    };

    return (
        <div className='team-section'>
            <h2>Our Vision<div className='vision-statement'><i>Actualize Your Dreams: Expert-Driven & AI-Enhanced Personalized Guidance</i>
</div></h2>
            <p className='vision'>Throughout our lives, we often find ourselves at crossroads, compelled to make decisions that shape our futures. Whether it is deliberating over a job offer, selecting a university for further education, or planning our career trajectory, these moments are pivotal yet appear baffling without an experienced guide. Unfortunately, the pathways to our aspirations often remain unknown to many, due to dearth of resources and mentorship. “Which path is better for me? Which universities should I apply to? What more can I do to aim higher? Does my application sound right for this position? What would this degree cost me? Is this decision worth the risk?” Most people struggle with questions like these because they don't have the help, guidance and information they need.<br></br><br></br>

These large information gaps between students and potential career pathways are persistent, creating obstacles that hinder their progress toward fulfilling futures. In these situations, getting timely advice and counseling can be life-changing!<br></br><br></br>

Think about a time when a family member or neighbor gave you advice that helped you see things differently. Their guidance made complex choices easier and showed us what we could achieve. People like these not only help us through difficult times but also inspire us by showing us what's possible with the right support. Unfortunately, such personalized mentorship is not available to many — if not most.<br></br><br></br>

This informs the core vision of ActualAIz, which aims to provide expert-driven & AI-enhanced personalized guidance for everyone.<br></br><br></br>

An ideal counselor embodies a blend of qualities that can significantly impact an individual's journey toward achieving their career goals. They offer personalized, relevant, and actionable advice, recognizing that each person's career aspirations, skills, interests, and background are uniquely their own. With a keen understanding of current market trends, they provide insights that are both locally and globally informed, ensuring opportunities are seen from every possible angle. Their approach is empathetic and uplifting, aiming to encourage and inspire at every step. Moreover, this invaluable guidance needs to be both affordable and accessible, ensuring that no one is left behind in the pursuit of their purpose and success.<br></br><br></br>

Our mission is guided by three simple goals.<br></br><br></br>
<ol className='list-vision'>
    <li className='item'>We aim to empower individuals by providing them access to vital information about career pathways, thereby helping them to envision the broad spectrum of possibilities that lie ahead.</li>
    <li className='item'>We aim to increase employment by effectively matching people with jobs that suit their unique skills and aspirations, facilitating a smoother transition into the workforce.</li>
    <li className='item'>We are committed to reducing poverty; by equipping people with the necessary tools and knowledge, we assist them in breaking free from poverty traps.</li>
</ol>
</p>
            <h2>Co-Founders</h2>
            <div className='team-leads'>
                {teamMembers.slice(0, 3).map(member => (
                    <div key={member.id} className='team-member' onClick={() => openModal(member)}>
                        <img src={member.img} alt={member.name} />
                        <p>{member.name}</p>
                    </div>
                ))}
            </div>

            <h2>Team</h2>
            <div className='team-members'>
                {teamMembers.slice(3).map(member => (
                    <div key={member.id} className='team-member' onClick={() => openModal(member)}>
                        <img src={member.img} alt={member.name} />
                        <p>{member.name}</p>
                    </div>
                ))}
            </div>

            {modalOpen && selectedMember && (
                <div className='modal'>
                    <div className='modal-content'>
                        <div className='close' onClick={closeModal}>
                            <CloseIcon />
                        </div>
                        <img className='modal-image' src={selectedMember.img} alt={selectedMember.name} />
                        <div className='test'>
                            <h3 className='name'>{selectedMember.name}</h3>
                            <p className='info'>{selectedMember.info}</p>
                            <div className='navi'>
                                <div className='prev' onClick={previousMember}>
                                    <NavigateBeforeIcon />
                                </div>
                                <div className='next' onClick={nextMember}>
                                    <NavigateNextIcon />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Team;
