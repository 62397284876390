import React from 'react';

function ProductsAndServices() {
    // Inline styles similar to the FAQ page
    const pageStyle: React.CSSProperties = {
        backgroundColor: '#000',
        color: '#fff',
        padding: '20px',
        fontFamily: 'Arial, sans-serif',
        lineHeight: '1.6',
        fontSize: '14px',
        minHeight: '100vh',  // Ensure the FAQ page takes the full viewport height
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    };
    // const containerStyle: React.CSSProperties = {
    //     backgroundColor: '#000',
    //     color: '#fff',
    //     padding: '20px',
    //     fontFamily: 'Arial, sans-serif',
    //     lineHeight: '1.6',
    //     fontSize: '14px',
    // };

    const headingStyle: React.CSSProperties = {
        color: '#FFD700',
        marginBottom: '10px',
        fontSize: '22px',
        marginTop: '100px'
    };

    const subHeadingStyle: React.CSSProperties = {
        color: '#FF8C00',
        marginTop: '20px',
        marginBottom: '10px',
        fontSize: '18px',
    };

    const paragraphStyle: React.CSSProperties = {
        marginBottom: '10px',
    };

    const listStyle: React.CSSProperties = {
        marginBottom: '20px',
        paddingLeft: '20px',
    };

    const listItemStyle: React.CSSProperties = {
        marginBottom: '5px',
    };

    const tableStyle: React.CSSProperties = {
        width: '100%',
        marginTop: '20px',
        marginBottom: '20px',
        borderCollapse: 'collapse',
    };

    const thStyle: React.CSSProperties = {
        backgroundColor: '#FF8C00',
        color: '#fff',
        padding: '10px',
        textAlign: 'left', // TypeScript now recognizes this as valid CSS
        borderBottom: '1px solid #fff',
    };

    const tdStyle: React.CSSProperties = {
        padding: '10px',
        color: '#fff',
        borderBottom: '1px solid #fff',
    };

    return (
        <div style={pageStyle}>
            <h1 style={headingStyle}>Products and Services</h1>
            <p style={paragraphStyle}>ActualAIz is currently launching with three services listed below:</p>
            <ul style={listStyle}>
                <li style={listItemStyle}>
                    <strong>Grad Assist (Paid):</strong> A profile evaluation and university recommendation tool that provides tailored feedback and university recommendations to prospective graduate students.
                </li>
                <li style={listItemStyle}>
                    <strong>SOPify (Paid):</strong> {`A Statement of Purpose evaluation tool that offers feedback on the structure and content of students' Statements of Purpose for graduate school admissions.`}
                </li>
                <li style={listItemStyle}>
                    <strong>Grad Echo (Free):</strong> A forum for discussions related to graduate school featuring verified professors and prospective graduate students.
                </li>
            </ul>

            <h2 style={subHeadingStyle}>Business Model</h2>
            <p style={paragraphStyle}>
                ActualAIz offers two paid services: Grad Assist and SOPify. Both operate on a pay-per-use model, with each complete use of a service referred to as a “run”. Customers can purchase runs separately for each service, with two available bundles: the Starter Pack and the Saver Pack.
            </p>

            <h3 style={subHeadingStyle}>Pricing</h3>
            <table style={tableStyle}>
                <thead>
                    <tr>
                        <th style={thStyle}>Service</th>
                        <th style={thStyle}>Starter Pack (3 runs)</th>
                        <th style={thStyle}>Saver Pack (6 runs)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={tdStyle}>Grad Assist</td>
                        <td style={tdStyle}>$7.99</td>
                        <td style={tdStyle}>$13.99</td>
                    </tr>
                    <tr>
                        <td style={tdStyle}>SOPify</td>
                        <td style={tdStyle}>$9.99</td>
                        <td style={tdStyle}>$17.99</td>
                    </tr>
                </tbody>
            </table>

            <p style={paragraphStyle}>
                Grad Echo is provided free of charge to all graduate students and is a valuable resource for discussing graduate school-related topics.
            </p>
        </div>
    );
}

export default ProductsAndServices;
