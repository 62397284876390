import React from 'react';

function FAQ() {
    // Inline styles for the black theme and full-page coverage
    const pageStyle: React.CSSProperties = {
        backgroundColor: '#000',
        color: '#fff',
        padding: '20px',
        fontFamily: 'Arial, sans-serif',
        lineHeight: '1.6',
        fontSize: '14px',
        minHeight: '100vh',  // Ensure the FAQ page takes the full viewport height
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    };

    const headingStyle: React.CSSProperties = {
        color: '#FFD700',
        marginBottom: '10px',
        fontSize: '22px',
        textAlign: 'left',
        marginTop: '100px'
    };

    const subHeadingStyle: React.CSSProperties = {
        color: '#FF8C00',
        marginTop: '20px',
        marginBottom: '10px',
        fontSize: '18px',
        textAlign: 'left',
    };

    const paragraphStyle: React.CSSProperties = {
        marginBottom: '10px',
        textAlign: 'left',
    };

    const listStyle: React.CSSProperties = {
        marginBottom: '20px',
        paddingLeft: '20px',
        listStyleType: 'disc',
    };

    const listItemStyle: React.CSSProperties = {
        marginBottom: '5px',
    };

    const emailStyle: React.CSSProperties = {
        color: '#FFD700',
        fontStyle: 'italic',
    };

    return (
        <div style={pageStyle}>
            <h1 style={headingStyle}>{`Frequently Asked Questions (FAQs)`}</h1>

            <h2 style={subHeadingStyle}>{`Get in Touch`}</h2>
            <p style={paragraphStyle}>
                {`Email Us: For inquiries, feedback, or support, please reach out to us at `}
                <strong style={emailStyle}>{`support@actualaiz.com`}</strong>
                {`. We strive to respond to all emails within 2 working days.`}
            </p>

            <h2 style={subHeadingStyle}>{`What services does ActualAIz offer?`}</h2>
            <p style={paragraphStyle}>{`ActualAIz currently offers three services:`}</p>
            <ul style={listStyle}>
                <li style={listItemStyle}>
                    <strong>{`Grad Assist`}</strong>
                    {`: A paid service that provides profile evaluation and university recommendations for prospective graduate students.`}
                </li>
                <li style={listItemStyle}>
                    <strong>{`SOPify`}</strong>
                    {`: A paid service that evaluates your Statement of Purpose and offers feedback on its structure and content for graduate school admissions.`}
                </li>
                <li style={listItemStyle}>
                    <strong>{`Grad Echo`}</strong>
                    {`: A free forum for discussions related to graduate school, featuring verified professors and prospective graduate students.`}
                </li>
            </ul>

            <h2 style={subHeadingStyle}>{`How do the paid services work?`}</h2>
            <p style={paragraphStyle}>
                {`Our paid services, Grad Assist and SOPify, operate on a pay-per-use model. Each complete use of a service is referred to as a "run." You can purchase runs separately, with a minimum of three runs available in a bundled price called the starter pack, or six runs in a discounted saver pack.`}
            </p>

            <h2 style={subHeadingStyle}>{`How can I purchase runs for Grad Assist or SOPify?`}</h2>
            <p style={paragraphStyle}>
                {`You can purchase runs for Grad Assist and SOPify directly through our website. Simply select the service you wish to use, choose the number of runs, and complete your purchase.`}
            </p>

            <h2 style={subHeadingStyle}>{`Is Grad Echo really free?`}</h2>
            <p style={paragraphStyle}>
                {`Yes, Grad Echo is a free service provided at no additional cost. It serves as a platform for discussions related to graduate school among verified professors and prospective graduate students.`}
            </p>

            <h2 style={subHeadingStyle}>{`What kind of feedback can I expect from Grad Assist?`}</h2>
            <p style={paragraphStyle}>
                {`Grad Assist provides tailored feedback on your profile and personalized university recommendations based on your academic background, interests, and goals.`}
            </p>

            <h2 style={subHeadingStyle}>{`How does SOPify evaluate my Statement of Purpose?`}</h2>
            <p style={paragraphStyle}>
                {`SOPify assesses the structure and content of your Statement of Purpose, offering constructive feedback to enhance its effectiveness for graduate school admissions.`}
            </p>

            <h2 style={subHeadingStyle}>{`Can I use Grad Assist and SOPify for multiple applications?`}</h2>
            <p style={paragraphStyle}>
                {`Yes, you can use both services for multiple applications. Each run can be utilized for a different application or to refine your materials further.`}
            </p>

            <h2 style={subHeadingStyle}>{`Are there any discounts available for purchasing multiple runs?`}</h2>
            <p style={paragraphStyle}>
                {`Yes, we offer bundled pricing for our paid services. You can purchase a starter pack with a minimum of three runs or a saver pack with six runs at a discounted rate.`}
            </p>

            <h2 style={subHeadingStyle}>{`How do I contact customer support if I have more questions?`}</h2>
            <p style={paragraphStyle}>
                {`You can reach our customer support team through the contact form on our website or by emailing us directly at `}
                <strong style={emailStyle}>{`support@actualaiz.com`}</strong>
                {`.`}
            </p>

            <h2 style={subHeadingStyle}>{`How does ActualAIz use AI in its services?`}</h2>
            <p style={paragraphStyle}>
                {`All our paid services leverage expert-driven AI to provide personalized feedback and recommendations, ensuring that you receive the most relevant and effective guidance for your graduate school applications.`}
            </p>

            <h2 style={subHeadingStyle}>{`How can I get a refund?`}</h2>
            <p style={paragraphStyle}>
                {`All purchases completed consensually are non-refundable, except in the case of a malfunction with our service. In the event of a malfunctioning or incomplete service (e.g., if you believe the ActualAIz generated output was incomplete), please share feedback by clicking the “share feedback” button under each service. Additionally, please email us at `}
                <strong style={emailStyle}>{`support@actualaiz.com`}</strong>
                {`, and our customer support representatives will get in touch within 2 working days. Customers have the option of choosing a monetary refund or a credit of runs within their ActualAIz account in the event of a successful refund claim.`}
            </p>
        </div>
    );
}

export default FAQ;
