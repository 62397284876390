import React, { useState, useEffect } from "react";
import Typist from "react-typist";
import "./Contact.css";

const Contact: React.FC = () => {
  return (
    <div className="contact-container">
      <div className="email">
        Contact email:{" "}
        <a
          href="mailto:support@actualaiz.com"
          style={{ color: "rgb(135, 135, 135)" }}
        >
          support@actualaiz.com
        </a>
      </div>
      <div className="email">
        Contact Address:{" "}
        <a style={{ color: "rgb(135, 135, 135)" }}>
          House No. 666, Block G, Street 29, Phase 5, DHA, Lahore, Pakistan
        </a>
      </div>
      <div className="email">
        Contact Number:{" "}
        <a style={{ color: "rgb(135, 135, 135)" }}>+92-321-6004207</a>
      </div>

      <div className="social-media-contacts">
        <a
          href="https://www.instagram.com/actualaiz"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="insta" src="Instagram.png" alt="Instagram" />
        </a>
        <a
          href="https://www.facebook.com/profile.php?id=61559163372895&mibextid=qi2Omg&rdid=XS2npHqPisaR5XwV"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="fb" src="Facebook.png" alt="X" />
        </a>
        <a
          href="https://www.linkedin.com/company/actualaiz/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="ln" src="LinkedIn.png" alt="LinkedIn" />
        </a>
        <a
          href="https://www.youtube.com/@Actualaiz"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="yt" src="Youtube.png" alt="YouTube" />
        </a>
        <a
          href="https://twitter.com/actualaiz?t=t4psN-zCgQa-9EiuGqRazw&s=08"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="x" src="X.png" alt="X" />
        </a>
      </div>
    </div>
  );
};

export default Contact;
