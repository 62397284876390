import React, { useEffect, useRef } from 'react';
import './Carosel.css';

const Carousel: React.FC = () => {
  const carouselRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (carouselRef.current) {
        const { current: carousel } = carouselRef;
        if (carousel.scrollLeft >= (carousel.scrollWidth - carousel.clientWidth) / 2) {
          carousel.scrollLeft = 0;
        } else {
          carousel.scrollLeft += 1;
        }
      }
    }, 50);

    return () => clearInterval(interval);
  }, []);

  const items: string[] = [
    "We have 30+ years of graduate school counselling experience.",
    "We have mentored 1000+ students into leading MS/PhD programs globally.",
    "Our team comprises experts with PhDs and postdocs from top universities.",
    "Our mentored students have been placed in MIT, CMU, Stanford, Berkeley, Harvard, UIUC, ETH Zurich, EPFL, and others.",
    "Our expert-driven AI powered platform provides personalized recommendations and guidance."

    // Our expert-driven AI powered platform provides personalized recommendations and guidance
    // "Expert-driven AI to provide tailored recommendations and guidance."
    // "Multi-country educational experiences",
    // "300+ placements in top M.S/PhD programs globally."
  ];

  return (
    <div className="carousel" ref={carouselRef}>
      <div className="carousel-inner">
        {items.map((item, index) => (
          <div key={index} className="carousel-item">
            {item}
          </div>
        ))}
        {items.map((item, index) => (
          <div key={`duplicate-${index}`} className="carousel-item">
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
